
export interface IPaymentEndpointProvider {
  createGuestCheckoutSession: string;
  abortGuestCheckoutSession: string;
  createCheckoutSession: string;
  abortCheckoutSession: string;
}

export const PaymentEndpointProvider: IPaymentEndpointProvider = {
  createGuestCheckoutSession: 'createGuestCheckoutSession',
  abortGuestCheckoutSession: 'abortGuestCheckoutSession',
  createCheckoutSession: 'createCheckoutSession',
  abortCheckoutSession: 'abortCheckoutSession',
};

export const EmulatorPaymentEndpointProvider: IPaymentEndpointProvider = {
  createGuestCheckoutSession: 'createGuestCheckoutSession',
  abortGuestCheckoutSession: 'abortGuestCheckoutSession',
  createCheckoutSession: 'createCheckoutSession',
  abortCheckoutSession: 'abortCheckoutSession',
};