/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { logError, logInfo } from 'in-time-logger';
import { AnalyticsTracker } from './analytics-tracker';
import { Renderer2 } from '@angular/core';
import { fromEnumAsString, isNullOrUndefinedOrEmpty, toSafeFilename } from 'in-time-core';
import {
  FB_EVT, FB_EVT_STD_ADD_TO_CART, FB_EVT_STD_COMPLETE_REGISTRATION, FB_EVT_CTM_INITIATE_PURCHASE,
  FB_EVT_STD_INITIATE_CHECKOUT, FB_EVT_STD_PURCHASE, FB_EVT_STD_SEARCH, FB_EVT_STD_VIEW_CONTENT,
  FB_EVT_STD_PAGE_VIEW
} from './analytics.events';
import {
  AddToCartEventArgs, AnalyticsTrackerType, CompleteRegistrationEventArgs, ContentViewEventArgs,
  InitiateCheckoutEventArgs, InitiatePurchaseEventArgs, PageViewEventArgs, PromoCodeEventArgs,
  PurchaseCompleteEventArgs, PurchaseFailedEventArgs, SearchEventArgs, SignUpEventArgs, PurchaseCanceledEventArgs,
  ExploreEventArgs,
} from './analytics.types';

const DEFAULT_META_PIXEL_ID: string = '458244612680217';
const META_PIXEL_SCRIPT_ID: string = 'meta-pixel-script';

const META_PIXEL_CODE: string = `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '${DEFAULT_META_PIXEL_ID}');
fbq('track', 'PageView');`;

declare const fbq: any;

export class MetaTracker extends AnalyticsTracker {
  private readonly initializedPixels: Set<string> = new Set<string>();
  private readonly customPixelStack: string[] = [];
  private hasAcceptedCookies: boolean = false;
  private hasBeenInitialized: boolean = false;

  public readonly $typeId: AnalyticsTrackerType = 'meta';

  static create(opts: {
    renderer: Renderer2,
    document: Document,
  }): MetaTracker {
    return new MetaTracker(opts.renderer, opts.document);
  }

  private constructor(
    private readonly renderer: Renderer2,
    private readonly _document: Document,
  ) { super(); }

  onInit(hasAcceptedCookies: boolean): void {
    const scriptElement = this.renderer.createElement('script');
    this.renderer.setAttribute(scriptElement, 'id', META_PIXEL_SCRIPT_ID);
    this.renderer.setAttribute(scriptElement, 'type', 'text/javascript');
    this.renderer.setProperty(scriptElement, 'innerHTML', META_PIXEL_CODE);
    this.renderer.appendChild(this._document.head, scriptElement);

    this.initializedPixels.add(DEFAULT_META_PIXEL_ID);

    this.hasAcceptedCookies = hasAcceptedCookies;
    this.hasBeenInitialized = true;
    logInfo('Facebook pixel script has been added!');
  }

  onDestroy(): void {
    // nothing to do here :)
  }

  onCookiesAccepted(): void {
    this.hasAcceptedCookies = true;
  }

  onCookiesDeclined(): void {
    this.hasAcceptedCookies = false;
  }

  activateCustomPixel(opts: { context: string, customPixelId: string, trackPageView: boolean }): void {
    if(!this.hasBeenInitialized || isNullOrUndefinedOrEmpty(opts.customPixelId)) return;

    try {
      if(!this.initializedPixels.has(opts.customPixelId)) {
        fbq('init', opts.customPixelId);
        this.initializedPixels.add(opts.customPixelId);
      }

      this.customPixelStack.push(opts.customPixelId);
      if(opts.trackPageView) {
        fbq('trackSingle', opts.customPixelId, FB_EVT_STD_PAGE_VIEW);
      }

      logInfo(`Custom Meta pixel has been activated for {${opts.context}}${opts.trackPageView ? ' with page view.' : ''}.`);
    }
    catch(error) {
      logError(`Failed to activate custom Meta pixel for {${opts.context}}.`, error);
    }
  }

  deactivateCustomPixel(opts: { context: string, customPixelId: string }): void {
    for(let i = this.customPixelStack.length - 1; i >= 0; i--) {
      if(this.customPixelStack[i] == opts.customPixelId) {
        this.customPixelStack.splice(i, 1);
        logInfo(`Custom Meta pixel has been deactivated for {${opts.context}}.`);
        return;
      }
    }
  }

  trackPromoCode(args: PromoCodeEventArgs): void {
    // nothing to do here :)
  }

  trackSignUp(args: SignUpEventArgs): void {
    // nothing to do here :)
  }

  trackPageView(args: PageViewEventArgs): void {
    // nothing to do here :)
  }

  trackContentView(args: ContentViewEventArgs): void {
    this.trackStandard(FB_EVT_STD_VIEW_CONTENT, {
      content_category: args.category,
      content_name: args.title,
      event_id: args.contentId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      ...args.queryParams,
    });
  }

  trackSearch(args: SearchEventArgs): void {
    this.trackStandard(FB_EVT_STD_SEARCH, {
      search_string: args.query,
      content_category: args.category,
      ...args.queryParams,
    });
  }

  trackExplore(args: ExploreEventArgs): void {
    // nothing to do here :)
  }

  trackAddToCart(args: AddToCartEventArgs): void {
    this.trackStandard(FB_EVT_STD_ADD_TO_CART, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      content_name: args.eventName,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      content_type: 'product',
      contents: args.cart.items.map((item) => ({
        id: item.uniqueId,
        quantity: item.quantity,
        currency: fromEnumAsString(item.totalPrice.currency),
        name: item.name.getAny(args.language) ?? item.uniqueId,
        unit_price: item.unitPrice.asDouble,
        total_item_price: item.totalPrice.asDouble,
      }))
    });

    this.trackCustom(`AddToCart_${this.toSafeEventName(args.eventName, args.eventDate)}`, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      content_name: args.eventName,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      content_type: 'product',
      contents: args.cart.items.map((item) => ({
        id: item.uniqueId,
        quantity: item.quantity,
        currency: fromEnumAsString(item.totalPrice.currency),
        name: item.name.getAny(args.language) ?? item.uniqueId,
        unit_price: item.unitPrice.asDouble,
        total_item_price: item.totalPrice.asDouble,
      }))
    });
  }

  trackInitiateCheckout(args: InitiateCheckoutEventArgs): void {
    this.trackStandard(FB_EVT_STD_INITIATE_CHECKOUT, {
      content_category: 'event',
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      content_name: args.eventName,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      content_type: 'product',
      num_items: args.cart.totalQuantity,
      contents: args.cart.items.map((item) => ({
        id: item.uniqueId,
        quantity: item.quantity,
        currency: fromEnumAsString(item.totalPrice.currency),
        name: item.name.getAny(args.language) ?? item.uniqueId,
        unit_price: item.unitPrice.asDouble,
        total_item_price: item.totalPrice.asDouble,
      }))
    });
  }

  trackCompleteRegistration(args: CompleteRegistrationEventArgs): void {
    this.trackStandard(FB_EVT_STD_COMPLETE_REGISTRATION, {
      status: true,
      content_category: 'event',
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      content_name: args.eventName,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      content_type: 'product',
      num_items: args.cart.totalQuantity,
      contents: args.cart.items.map((item) => ({
        id: item.uniqueId,
        quantity: item.quantity,
        currency: fromEnumAsString(item.totalPrice.currency),
        name: item.name.getAny(args.language) ?? item.uniqueId,
        unit_price: item.unitPrice.asDouble,
        total_item_price: item.totalPrice.asDouble,
      }))
    });
  }

  trackInitiatePurchase(args: InitiatePurchaseEventArgs): void {
    this.trackCustom(FB_EVT_CTM_INITIATE_PURCHASE, {
      content_category: 'event',
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      content_name: args.eventName,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      content_type: 'product',
      num_items: args.cart.totalQuantity,
      contents: args.cart.items.map((item) => ({
        id: item.uniqueId,
        quantity: item.quantity,
        currency: fromEnumAsString(item.totalPrice.currency),
        name: item.name.getAny(args.language) ?? item.uniqueId,
        unit_price: item.unitPrice.asDouble,
        total_item_price: item.totalPrice.asDouble,
      }))
    });
  }

  trackPurchaseComplete(args: PurchaseCompleteEventArgs): void {
    this.trackStandard(FB_EVT_STD_PURCHASE, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      num_items: args.cart.items.length,
      content_name: args.eventName,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      content_type: 'product',
      contents: args.cart.items.map((item) => ({
        id: item.uniqueId,
        quantity: item.quantity,
        currency: fromEnumAsString(item.totalPrice.currency),
        name: item.name.getAny(args.language) ?? item.uniqueId,
        unit_price: item.unitPrice.asDouble,
        total_item_price: item.totalPrice.asDouble,
      })),
    });

    this.trackCustom(`Purchase_${this.toSafeEventName(args.eventName, args.eventDate)}`, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      num_items: args.cart.items.length,
      content_name: args.eventName,
      event_name: args.eventName,
      event_id: args.eventId,
      business_id: args.businessId,
      organization_id: args.organizationId,
      content_type: 'product',
      contents: args.cart.items.map((item) => ({
        id: item.uniqueId,
        quantity: item.quantity,
        currency: fromEnumAsString(item.totalPrice.currency),
        name: item.name.getAny(args.language) ?? item.uniqueId,
        unit_price: item.unitPrice.asDouble,
        total_item_price: item.totalPrice.asDouble,
      })),
    });
  }

  trackPurchaseFailed(args: PurchaseFailedEventArgs): void {
    // nothing to do here :)
  }

  trackPurchaseCanceled(args: PurchaseCanceledEventArgs): void {
    // nothing to do here :)
  }

  private trackStandard(eventId: string, parameters: unknown): void {
    if(!this.hasBeenInitialized) return;
    if(!this.hasAcceptedCookies) return;

    try {
      fbq('trackSingle', DEFAULT_META_PIXEL_ID, eventId, parameters);
      if(this.customPixelStack.length > 0) {
        fbq('trackSingle', this.customPixelStack[this.customPixelStack.length - 1], eventId, parameters);
      }

      logInfo(`Tracking Facebook event {${eventId}} with params: ${JSON.stringify(parameters, null, 2)}`);
    }
    catch(error) {
      logError(`Failed to track Facebook event {${eventId}} with params: ${JSON.stringify(parameters, null, 2)}`, error);
    }
  }

  private trackCustom(eventId: string, parameters: unknown): void {
    if(!this.hasBeenInitialized) return;
    if(!this.hasAcceptedCookies) return;

    try {
      fbq('trackSingleCustom', DEFAULT_META_PIXEL_ID, eventId, parameters);
      if(this.customPixelStack.length > 0) {
        fbq('trackSingleCustom', this.customPixelStack[this.customPixelStack.length - 1], eventId, parameters);
      }

      logInfo(`Tracking custom Facebook event {${eventId}} with params: ${JSON.stringify(parameters, null, 2)}`);
    }
    catch(error) {
      logError(`Failed to track custom Facebook event {${eventId}} with params: ${JSON.stringify(parameters, null, 2)}`, error);
    }
  }
}